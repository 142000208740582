/**
 * @packageDocumentation
 * @module api.functional.api.admin.users.cognito
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { GetCognitoUserDto } from "../../../../../../../contracts/src/user/get-cognito-user.dto";

/**
 * @controller AdminUsersController.getCognitoUserById
 * @path GET /api/admin/users/cognito/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getCognitoUserById(
  connection: IConnection,
  id: string,
): Promise<getCognitoUserById.Output> {
  return PlainFetcher.fetch(connection, {
    ...getCognitoUserById.METADATA,
    path: getCognitoUserById.path(id),
  });
}
export namespace getCognitoUserById {
  export type Output = Primitive<GetCognitoUserDto>;

  export const METADATA = {
    method: "GET",
    path: "/api/admin/users/cognito/:id",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string) =>
    `/api/admin/users/cognito/${encodeURIComponent(id ?? "null")}`;
}
