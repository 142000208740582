/**
 * @packageDocumentation
 * @module api.functional.api.payment.payment_intent
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { CreatePurchaseDto } from "../../../../../../contracts/src/payment/create-purchase.dto";
import type { PaymentIntentCreationDetails } from "../../../../../../contracts/src/payment/payment.dto";

/**
 * @controller PaymentController.createPaymentIntent
 * @path POST /api/payment/payment-intent
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function createPaymentIntent(
  connection: IConnection,
  purchaseDto: createPaymentIntent.Input,
): Promise<createPaymentIntent.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...createPaymentIntent.METADATA,
      path: createPaymentIntent.path(),
    },
    purchaseDto,
  );
}
export namespace createPaymentIntent {
  export type Input = Primitive<CreatePurchaseDto>;
  export type Output = Primitive<PaymentIntentCreationDetails>;

  export const METADATA = {
    method: "POST",
    path: "/api/payment/payment-intent",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/payment/payment-intent";
}
