import { IHttpClient } from '../types';
import {
  IExtendedUser,
  IUpdateUser,
  TGetUserPlan,
  UpsertUserDto,
  ExtendedUserDto,
  Paginated,
} from '@flounder/contracts';
import api from '@flounder/backend-sdk';
import { IConnection } from '@nestia/fetcher';
import { createBackendSDKConnectionParamsForWeb } from '../createBackendSDKConnectionParamsForWeb';
import { HttpClient } from '@flounder/http-client';

export interface IUserApi {
  getUsers: () => Promise<Paginated<ExtendedUserDto>>;
  getCurrentUser: () => Promise<IExtendedUser>;
  updateUser: (userId: string, userData: IUpdateUser) => Promise<IUpdateUser>;
  getUser: (userId: string) => Promise<IExtendedUser>;
  getUserPlan: () => Promise<TGetUserPlan>;
  createUser: () => Promise<UpsertUserDto>;
}

export class UserApi implements IUserApi {
  private client: IHttpClient;

  private readonly backendSKDConnectionParams: IConnection;

  constructor(client: IHttpClient) {
    this.client = client as HttpClient;
    this.backendSKDConnectionParams = createBackendSDKConnectionParamsForWeb(
      client as HttpClient
    );
  }

  getUser(userId: string): Promise<IExtendedUser> {
    return api.functional.api.users.getById(
      this.backendSKDConnectionParams,
      userId
    );
  }

  async getCurrentUser(): Promise<IExtendedUser> {
    return await api.functional.api.users.me.getUser(
      this.backendSKDConnectionParams
    );
  }

  updateUser(userId: string, userData: IUpdateUser): Promise<IUpdateUser> {
    return api.functional.api.users.update(
      this.backendSKDConnectionParams,
      userId,
      userData
    );
  }

  getUsers(): Promise<Paginated<ExtendedUserDto>> {
    return api.functional.api.users.getMany(
      this.backendSKDConnectionParams,
      {}
    );
  }

  getUserPlan(): Promise<TGetUserPlan> {
    return api.functional.api.users.plan.me.getPlan(
      this.backendSKDConnectionParams
    );
  }

  createUser(): Promise<UpsertUserDto> {
    return api.functional.api.users.signIn(this.backendSKDConnectionParams);
  }
}

export const userApiFactory = (httpClient: IHttpClient) =>
  new UserApi(httpClient);
