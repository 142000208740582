export class AppConfig {
  get cognitoConfig() {
    return {
      cognitoRegion: this.getOrThrow('COGNITO_REGION'),
      cognitoUserPoolId: this.getOrThrow('COGNITO_USER_POOL_ID'),
      cognitoUserPoolWebClientId: this.getOrThrow('COGNITO_USER_POOL_WEB_CLIENT_ID'),
      cognitoOAuthDomain: this.getOrThrow('COGNITO_OAUTH_DOMAIN'),
      cognitoOAuthRedirectSignIn: this.getOrThrow('COGNITO_OAUTH_REDIRECT_SIGNIN'),
      cognitoOAuthRedirectSignOut: this.getOrThrow('COGNITO_OAUTH_REDIRECT_SIGNOUT'),
    };
  }

  get stripeConfig() {
    return {
      stripePublishableKey: this.getOrThrow('NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY'),
      stripeSuccessPaymentUrl: this.getOrThrow('STRIPE_SUCCESS_PAYMENT_URL'),
      stripeBillingUrl: this.getOrThrow('NEXT_PUBLIC_STRIPE_BILLING_URL'),
    };
  }

  get captchaConfig() {
    return { captchaPublicKey: this.getOrThrow('NEXT_PUBLIC_CAPTCHA_SITE_KEY') };
  }

  get googleConfig() {
    return {
      googleClientId: this.getOrThrow('NEXT_PUBLIC_GOOGLE_CLIENT_ID'),
      googleApiKey: this.getOrThrow('NEXT_PUBLIC_GOOGLE_API_KEY'),
    };
  }

  get magicBellConfig() {
    return {
      magicBellClientId: this.getOrThrow('NEXT_PUBLIC_MAGICBELL_API_KEY'),
    };
  }

  get config() {
    return {
      cognitoConfig: this.cognitoConfig,
      stripeConfig: this.stripeConfig,
      captchaConfig: this.captchaConfig,
      googleConfig: this.googleConfig,
      magicBellConfig: this.magicBellConfig,
    };
  }

  private getOrThrow(key: string) {
    const env = process.env[key];
    if (!env) throw new Error(`Env ${key} not found.`);
    return env;
  }
}
