/**
 * @packageDocumentation
 * @module api.functional.api.registration
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { ISignUp } from "../../../../../contracts/src/custom-auth/sign-up.schema.type";
import type { IUpsertUser } from "../../../../../contracts/src/user/upsert-user.schema.type";

/**
 * @controller RegistrationController.registerUser
 * @path POST /api/registration
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function registerUser(
  connection: IConnection,
  dto: registerUser.Input,
): Promise<registerUser.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...registerUser.METADATA,
      path: registerUser.path(),
    },
    dto,
  );
}
export namespace registerUser {
  export type Input = Primitive<ISignUp>;
  export type Output = Primitive<IUpsertUser>;

  export const METADATA = {
    method: "POST",
    path: "/api/registration",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/registration";
}
