import '../styles/globals.css';
import { useEffect, useState } from 'react';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { Inter } from '@next/font/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import { AuthProvider } from '@flounder/cognito-auth/AuthContext';
import { CognitoApi } from '@flounder/cognito-auth/CognitoApi/CognitoApi';
import { IExtendedUser } from '@flounder/contracts';
import { createHttpClient } from '@flounder/http-client';
import { getEnvVariables, LoggerProvider } from '@flounder/next-utils';
import { UserApi } from '@flounder/shared-apis';
import { defaultLocale, loadCatalog } from '../i18n';
import { AppConfig } from '../modules/AppConfig/AppConfig';
import { AppConfigProvider } from '../modules/AppConfig/AppConfigProvider';
import { Toaster } from '../modules/Toast';
import { NextPageWithLayout } from '../utils';

const inter = Inter({
  subsets: ['latin'],
  weight: ['400', '500', '700'],
  variable: '--font-inter',
});

type PageProps = { currentUser?: IExtendedUser; dehydratedState?: unknown };

type CustomAppProps = {
  config: AppConfig;
  Component: NextPageWithLayout<PageProps>;
} & AppProps<PageProps>;

const MyApp = ({ Component, pageProps, router, config }: CustomAppProps) => {
  const currentUser = pageProps?.currentUser || null;

  const { cognitoConfig, googleConfig } = config;

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            retry: (failureCount, error: any) => {
              if (error?.response.status == 502 && failureCount > 3) return false;
              if (error?.response.status >= 400) return false;
              return true;
            },
          },
        },
      }),
  );

  const locale = router.locale;

  const [shortLocale] = locale ? locale.split('-') : [defaultLocale];

  useEffect(() => {
    loadCatalog(shortLocale);
  }, [shortLocale]);

  const getLayout = Component.getLayout ?? (page => page);

  return (
    <>
      <Head>
        <title>Flounder App</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/fish.ico" />
      </Head>

      <AppConfigProvider config={config}>
        <GoogleOAuthProvider clientId={googleConfig.googleClientId}>
          <LoggerProvider>
            <QueryClientProvider client={queryClient}>
              <AuthProvider
                ssrUser={currentUser}
                cognitoApi={new CognitoApi(Auth, { ...cognitoConfig })}
                authApi={new UserApi(createHttpClient({ url: getEnvVariables().PAGE_URL }))}
              >
                <HydrationBoundary state={pageProps.dehydratedState}>
                  <I18nProvider i18n={i18n}>
                    <div className={classNames('font-sans', inter.variable)}>
                      {Toaster}
                      {getLayout(<Component {...pageProps} />)}
                    </div>
                  </I18nProvider>
                </HydrationBoundary>
              </AuthProvider>
            </QueryClientProvider>
          </LoggerProvider>
        </GoogleOAuthProvider>
      </AppConfigProvider>
    </>
  );
};

MyApp.getInitialProps = async (ctx: AppContext) => {
  const appProps = await App.getInitialProps(ctx);

  const appConfig = new AppConfig();
  const { config, cognitoConfig } = appConfig;

  // We need configure Amplify first time at the top of app - before
  // withSession and Providers
  CognitoApi.configureAmplify(cognitoConfig);
  return { ...appProps, config };
};

export default MyApp;
