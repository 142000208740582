/**
 * @packageDocumentation
 * @module api.functional.api.events
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Resolved, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Paginated } from "nestjs-paginate/lib/paginate";

import type { IEvent } from "../../../../../contracts/src/event/event.schema.type";
import type { IGetManyQueryParams } from "../../../../../contracts/src/pagination/paginate-query.schema.type";

/**
 * @controller EventsController.getAll
 * @path GET /api/events
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getAll(
  connection: IConnection,
  requestQueryParams: getAll.Query,
): Promise<getAll.Output> {
  return PlainFetcher.fetch(connection, {
    ...getAll.METADATA,
    path: getAll.path(requestQueryParams),
  });
}
export namespace getAll {
  export type Query = Resolved<IGetManyQueryParams>;
  export type Output = Primitive<Paginated<IEvent>>;

  export const METADATA = {
    method: "GET",
    path: "/api/events",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (requestQueryParams: getAll.Query) => {
    const variables: URLSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(requestQueryParams as any))
      if (undefined === value) continue;
      else if (Array.isArray(value))
        value.forEach((elem: any) => variables.append(key, String(elem)));
      else variables.set(key, String(value));
    const location: string = "/api/events";
    return 0 === variables.size
      ? location
      : `${location}?${variables.toString()}`;
  };
}

/**
 * @controller EventsController.getOne
 * @path GET /api/events/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getOne(
  connection: IConnection,
  id: string,
): Promise<getOne.Output> {
  return PlainFetcher.fetch(connection, {
    ...getOne.METADATA,
    path: getOne.path(id),
  });
}
export namespace getOne {
  export type Output = Primitive<IEvent>;

  export const METADATA = {
    method: "GET",
    path: "/api/events/:id",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string) =>
    `/api/events/${encodeURIComponent(id ?? "null")}`;
}
