/**
 * @packageDocumentation
 * @module api.functional.api.admin.users.memberships
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

/**
 * @controller AdminUsersController.addUserToGroup
 * @path PUT /api/admin/users/:id/memberships/:groupName
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function addUserToGroup(
  connection: IConnection,
  id: string,
  groupName: string,
): Promise<void> {
  return PlainFetcher.fetch(connection, {
    ...addUserToGroup.METADATA,
    path: addUserToGroup.path(id, groupName),
  });
}
export namespace addUserToGroup {
  export const METADATA = {
    method: "PUT",
    path: "/api/admin/users/:id/memberships/:groupName",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string, groupName: string) =>
    `/api/admin/users/${encodeURIComponent(id ?? "null")}/memberships/${encodeURIComponent(groupName ?? "null")}`;
}

/**
 * @controller AdminUsersController.removeUserFromGroup
 * @path DELETE /api/admin/users/:id/memberships/:groupName
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function removeUserFromGroup(
  connection: IConnection,
  id: string,
  groupName: string,
): Promise<void> {
  return PlainFetcher.fetch(connection, {
    ...removeUserFromGroup.METADATA,
    path: removeUserFromGroup.path(id, groupName),
  });
}
export namespace removeUserFromGroup {
  export const METADATA = {
    method: "DELETE",
    path: "/api/admin/users/:id/memberships/:groupName",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string, groupName: string) =>
    `/api/admin/users/${encodeURIComponent(id ?? "null")}/memberships/${encodeURIComponent(groupName ?? "null")}`;
}
