/**
 * @packageDocumentation
 * @module api.functional.api.admin.users.block
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

/**
 * @controller AdminUsersController.blockUser
 * @path PUT /api/admin/users/:id/block
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function blockUser(
  connection: IConnection,
  id: string,
): Promise<void> {
  return PlainFetcher.fetch(connection, {
    ...blockUser.METADATA,
    path: blockUser.path(id),
  });
}
export namespace blockUser {
  export const METADATA = {
    method: "PUT",
    path: "/api/admin/users/:id/block",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string) =>
    `/api/admin/users/${encodeURIComponent(id ?? "null")}/block`;
}

/**
 * @controller AdminUsersController.unblockUser
 * @path DELETE /api/admin/users/:id/block
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function unblockUser(
  connection: IConnection,
  id: string,
): Promise<void> {
  return PlainFetcher.fetch(connection, {
    ...unblockUser.METADATA,
    path: unblockUser.path(id),
  });
}
export namespace unblockUser {
  export const METADATA = {
    method: "DELETE",
    path: "/api/admin/users/:id/block",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string) =>
    `/api/admin/users/${encodeURIComponent(id ?? "null")}/block`;
}
