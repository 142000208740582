import { t } from '@lingui/macro';
import { ZodIssueCode, ZodParsedType, ZodErrorMap } from 'zod';

// This is fork of zod-18n library that uses i18next under the hood
// We needed lingui
// Original source code: https://github.com/aiji42/zod-i18n/blob/main/packages/core/src/index.ts

const jsonStringifyReplacer = <T>(_: string, value: T): T | string => {
  if (typeof value === 'bigint') {
    return value.toString();
  }
  return value;
};

function joinValues<T extends unknown[]>(array: T, separator = ' | '): string {
  return array.map(val => (typeof val === 'string' ? `'${val}'` : val)).join(separator);
}

export const customErrorMap: ZodErrorMap = (issue, ctx) => {
  let message: string = ctx.defaultError;

  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = t({ id: 'errors.invalid_type_received_undefined' });
      } else {
        const expected = t({
          id: `types.${issue.expected}`,
        });
        const received = t({
          id: `types.${issue.received}`,
        });
        message = t({
          id: 'errors.invalid_type',
          message: `Expected ${expected}, received ${received}`,
        });
      }
      break;
    case ZodIssueCode.invalid_literal:
      message = t({
        id: 'errors.invalid_literal',
        message: `Expected ${JSON.stringify(issue.expected, jsonStringifyReplacer)}`,
      });
      break;
    case ZodIssueCode.unrecognized_keys:
      message = t({
        id: 'errors.unrecognized_keys',
        message: `Keys: ${joinValues(issue.keys, ', ')}, Count: ${issue.keys.length}`,
      });
      break;
    case ZodIssueCode.invalid_union:
      message = t({
        id: 'errors.invalid_union',
      });
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = t({
        id: 'errors.invalid_union_discriminator',
        message: `Options: ${joinValues(issue.options)}`,
      });
      break;
    case ZodIssueCode.invalid_enum_value:
      message = t({
        id: 'errors.invalid_enum_value',
        message: `Options: ${joinValues(issue.options)}, Received: ${issue.received}`,
      });
      break;
    case ZodIssueCode.invalid_arguments:
      message = t({
        id: 'errors.invalid_arguments',
      });
      break;
    case ZodIssueCode.invalid_return_type:
      message = t({
        id: 'errors.invalid_return_type',
      });
      break;
    case ZodIssueCode.invalid_date:
      message = t({
        id: 'errors.invalid_date',
      });
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === 'object') {
        if ('startsWith' in issue.validation) {
          message = t({
            id: `errors.invalid_string.startsWith`,
            message: `String must start with ${issue.validation.startsWith}`,
          });
        } else if ('endsWith' in issue.validation) {
          message = t({
            id: `errors.invalid_string.endsWith`,
            message: `String must end with ${issue.validation.endsWith}`,
          });
        }
      } else {
        const validation = t({
          id: `validations.${issue.validation}`,
        });

        message = t({
          id: `errors.invalid_string.${issue.validation}`,
          message: `Invalid string: ${validation}`,
        });
      }
      break;
    case ZodIssueCode.too_small: {
      const minimum = issue.type === 'date' ? new Date(issue.minimum as number) : issue.minimum;
      message = t({
        id: `errors.too_small.${issue.type}.${
          issue.exact ? 'exact' : issue.inclusive ? 'inclusive' : 'not_inclusive'
        }`,
        message: `Minimum value is ${minimum}`,
      });
      break;
    }
    case ZodIssueCode.too_big: {
      const maximum = issue.type === 'date' ? new Date(issue.maximum as number) : issue.maximum;
      message = t({
        id: `errors.too_big.${issue.type}.${
          issue.exact ? 'exact' : issue.inclusive ? 'inclusive' : 'not_inclusive'
        }`,
        message: `Maximum value is ${maximum}`,
      });
      break;
    }
    case ZodIssueCode.custom:
      message = t({
        id: issue.params?.i18n ?? 'errors.custom',
        message: issue.params?.values,
      });
      break;
    case ZodIssueCode.invalid_intersection_types:
      message = t({ id: 'errors.invalid_intersection_types' });
      break;
    case ZodIssueCode.not_multiple_of:
      message = t({
        id: 'errors.not_multiple_of',
        message: `Value must be a multiple of ${issue.multipleOf}`,
      });
      break;
    case ZodIssueCode.not_finite:
      message = t({
        id: 'errors.not_finite',
      });
      break;
    default:
  }

  return { message };
};
