/**
 * @packageDocumentation
 * @module api.functional.api.users
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive, Resolved } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { IGetManyQueryParams } from "../../../../../contracts/src/pagination/paginate-query.schema.type";
import type { Paginated } from "../../../../../contracts/src/types/paginated";
import type { ExtendedUserDto } from "../../../../../contracts/src/user/extended-user.dto";
import type { UpdateUserDto } from "../../../../../contracts/src/user/update-user.dto";
import type { UpsertUserDto } from "../../../../../contracts/src/user/upsert-user.dto";

export * as access_token from "./access_token";
export * as me from "./me";
export * as plan from "./plan";

/**
 * @controller UsersController.signIn
 * @path POST /api/users
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function signIn(connection: IConnection): Promise<signIn.Output> {
  return PlainFetcher.fetch(connection, {
    ...signIn.METADATA,
    path: signIn.path(),
  });
}
export namespace signIn {
  export type Output = Primitive<UpsertUserDto>;

  export const METADATA = {
    method: "POST",
    path: "/api/users",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/users";
}

/**
 * @controller UsersController.getMany
 * @path GET /api/users
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getMany(
  connection: IConnection,
  requestQueryParams: getMany.Query,
): Promise<getMany.Output> {
  return PlainFetcher.fetch(connection, {
    ...getMany.METADATA,
    path: getMany.path(requestQueryParams),
  });
}
export namespace getMany {
  export type Query = Resolved<IGetManyQueryParams>;
  export type Output = Primitive<Paginated<ExtendedUserDto>>;

  export const METADATA = {
    method: "GET",
    path: "/api/users",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (requestQueryParams: getMany.Query) => {
    const variables: URLSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(requestQueryParams as any))
      if (undefined === value) continue;
      else if (Array.isArray(value))
        value.forEach((elem: any) => variables.append(key, String(elem)));
      else variables.set(key, String(value));
    const location: string = "/api/users";
    return 0 === variables.size
      ? location
      : `${location}?${variables.toString()}`;
  };
}

/**
 * @controller UsersController.getById
 * @path GET /api/users/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getById(
  connection: IConnection,
  id: string,
): Promise<getById.Output> {
  return PlainFetcher.fetch(connection, {
    ...getById.METADATA,
    path: getById.path(id),
  });
}
export namespace getById {
  export type Output = Primitive<ExtendedUserDto>;

  export const METADATA = {
    method: "GET",
    path: "/api/users/:id",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string) =>
    `/api/users/${encodeURIComponent(id ?? "null")}`;
}

/**
 * @controller UsersController.update
 * @path PUT /api/users/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function update(
  connection: IConnection,
  id: string,
  updateUserDto: update.Input,
): Promise<update.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...update.METADATA,
      path: update.path(id),
    },
    updateUserDto,
  );
}
export namespace update {
  export type Input = Primitive<UpdateUserDto>;
  export type Output = Primitive<UpdateUserDto>;

  export const METADATA = {
    method: "PUT",
    path: "/api/users/:id",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string) =>
    `/api/users/${encodeURIComponent(id ?? "null")}`;
}
