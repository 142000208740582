import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Hub } from 'aws-amplify';
import { AuthProviderProps, IAuthContext, IAuthUser } from './Auth.interface';

const DEFAULT_AVATAR = '/avatar-logged.png';

export const AuthContext = createContext<IAuthContext>({
  cognitoApi: undefined,
  currentUser: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentAuthenticatedUser: async () => {},
});

export const AuthProvider = ({
  cognitoApi,
  ssrUser,
  authApi,
  children,
}: PropsWithChildren<AuthProviderProps>) => {
  const [currentUser, setUser] = useState<IAuthUser | null>(ssrUser);

  const setCurrentAuthenticatedUser = useCallback(async () => {
    const user = await authApi.getCurrentUser().catch(() => null);
    setUser(user);
  }, []);

  useEffect(() => {
    setCurrentAuthenticatedUser();

    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
          void setCurrentAuthenticatedUser();
          break;
        case 'signOut':
          setUser(null);
      }
    });
  }, [setCurrentAuthenticatedUser]);

  return (
    <AuthContext.Provider
      value={{
        cognitoApi,
        currentUser: currentUser || ssrUser,
        setCurrentAuthenticatedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  if (context.currentUser) {
    const { firstName, lastName } = context.currentUser;

    const fullName =
      firstName || lastName
        ? `${firstName || ''} ${lastName || ''}`.trim()
        : undefined;

    context.currentUser.avatarUrl =
      context.currentUser?.avatarUrl || DEFAULT_AVATAR;

    context.currentUser.fullName = fullName;
  }

  return context;
};
