/**
 * @packageDocumentation
 * @module api.functional.api.external_login.facebook
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type {
  FacebookLoginDto,
  FacebookLoginResponseDto,
} from "../../../../../../contracts/src/custom-auth/facebook/facebook-login.dto";
import type { TGetFacebookUrlResponseData } from "../../../../../../contracts/src/custom-auth/facebook/types";

/**
 * @controller ExternalLoginController.facebookLogin
 * @path POST /api/external-login/facebook
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function facebookLogin(
  connection: IConnection,
  dto: facebookLogin.Input,
): Promise<facebookLogin.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...facebookLogin.METADATA,
      path: facebookLogin.path(),
    },
    dto,
  );
}
export namespace facebookLogin {
  export type Input = Primitive<FacebookLoginDto>;
  export type Output = Primitive<FacebookLoginResponseDto>;

  export const METADATA = {
    method: "POST",
    path: "/api/external-login/facebook",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/external-login/facebook";
}

/**
 * @controller ExternalLoginController.getFacebookLoginUrl
 * @path GET /api/external-login/facebook
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getFacebookLoginUrl(
  connection: IConnection,
): Promise<getFacebookLoginUrl.Output> {
  return PlainFetcher.fetch(connection, {
    ...getFacebookLoginUrl.METADATA,
    path: getFacebookLoginUrl.path(),
  });
}
export namespace getFacebookLoginUrl {
  export type Output = Primitive<TGetFacebookUrlResponseData>;

  export const METADATA = {
    method: "GET",
    path: "/api/external-login/facebook",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/external-login/facebook";
}
