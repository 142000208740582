import React, { ReactNode } from 'react';
import { z, ZodErrorMap } from 'nestjs-zod/z';
import { GoogleReCaptchaProvider } from '@flounder/captcha-utils';
import { AuthConfigProvider } from '@flounder/next-utils';
import { GoogleConfigProvider } from '@flounder/places-autocomplete';
import { StripeConfigProvider } from '../../utils/StripeConfigContextProvider';
import { customErrorMap } from '../../utils/zodErrorMap';
import { MagicBellConfigProvider } from '../MagicBell';
import { AppConfig } from './AppConfig';

z.setErrorMap(customErrorMap as ZodErrorMap);

interface IAppConfigProviderProps {
  config: AppConfig;
  children: ReactNode;
}
export const AppConfigProvider = ({ config, children }: IAppConfigProviderProps) => {
  const { cognitoConfig, stripeConfig, captchaConfig, googleConfig, magicBellConfig } = config;

  return (
    <AuthConfigProvider config={cognitoConfig}>
      {/* Up to developer if it's better to use globally or per page */}
      <GoogleReCaptchaProvider
        reCaptchaKey={captchaConfig.captchaPublicKey}
        scriptProps={{
          async: true,
          defer: true,
        }}
      >
        <MagicBellConfigProvider config={magicBellConfig}>
          <GoogleConfigProvider config={googleConfig}>
            <StripeConfigProvider config={stripeConfig}>{children}</StripeConfigProvider>
          </GoogleConfigProvider>
        </MagicBellConfigProvider>
      </GoogleReCaptchaProvider>
    </AuthConfigProvider>
  );
};
