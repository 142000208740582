/**
 * @packageDocumentation
 * @module api.functional.api.admin.users
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Resolved, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { IGetManyQueryParams } from "../../../../../../contracts/src/pagination/paginate-query.schema.type";
import type { Paginated } from "../../../../../../contracts/src/types/paginated";
import type { ExtendedUserDto } from "../../../../../../contracts/src/user/extended-user.dto";
import type { UpdateUserDto } from "../../../../../../contracts/src/user/update-user.dto";

export * as cognito from "./cognito";
export * as block from "./block";
export * as memberships from "./memberships";
export * as description from "./description";

/**
 * @controller AdminUsersController.getMany
 * @path GET /api/admin/users
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getMany(
  connection: IConnection,
  requestQueryParams: getMany.Query,
): Promise<getMany.Output> {
  return PlainFetcher.fetch(connection, {
    ...getMany.METADATA,
    path: getMany.path(requestQueryParams),
  });
}
export namespace getMany {
  export type Query = Resolved<IGetManyQueryParams>;
  export type Output = Primitive<Paginated<ExtendedUserDto>>;

  export const METADATA = {
    method: "GET",
    path: "/api/admin/users",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (requestQueryParams: getMany.Query) => {
    const variables: URLSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(requestQueryParams as any))
      if (undefined === value) continue;
      else if (Array.isArray(value))
        value.forEach((elem: any) => variables.append(key, String(elem)));
      else variables.set(key, String(value));
    const location: string = "/api/admin/users";
    return 0 === variables.size
      ? location
      : `${location}?${variables.toString()}`;
  };
}

/**
 * @controller AdminUsersController.getUserById
 * @path GET /api/admin/users/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getUserById(
  connection: IConnection,
  id: string,
): Promise<getUserById.Output> {
  return PlainFetcher.fetch(connection, {
    ...getUserById.METADATA,
    path: getUserById.path(id),
  });
}
export namespace getUserById {
  export type Output = Primitive<ExtendedUserDto>;

  export const METADATA = {
    method: "GET",
    path: "/api/admin/users/:id",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string) =>
    `/api/admin/users/${encodeURIComponent(id ?? "null")}`;
}

/**
 * @controller AdminUsersController.updateUser
 * @path PATCH /api/admin/users/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function updateUser(
  connection: IConnection,
  id: string,
  updateUserRequestData: updateUser.Input,
): Promise<updateUser.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...updateUser.METADATA,
      path: updateUser.path(id),
    },
    updateUserRequestData,
  );
}
export namespace updateUser {
  export type Input = Primitive<UpdateUserDto>;
  export type Output = Primitive<UpdateUserDto>;

  export const METADATA = {
    method: "PATCH",
    path: "/api/admin/users/:id",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string) =>
    `/api/admin/users/${encodeURIComponent(id ?? "null")}`;
}
