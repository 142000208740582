export type TNewPredictions = {
  id: string;
  label: string;
  value: string;
};

export type TPlaceDetails = google.maps.places.PlaceResult | null;

export enum GoogleAddressParts {
  STREET_NAME = 'route',
  STREET_NUMBER = 'street_number',
  DISTRICT = 'sublocality_level_1',
  LOCALITY = 'locality',
  ADMIN_AREA_LEVEL_2 = 'administrative_area_level_2',
  ADMIN_AREA = 'administrative_area_level_1',
  COUNTRY = 'country',
  POSTAL_CODE = 'postal_code',
}
