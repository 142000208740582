/**
 * @packageDocumentation
 * @module api.functional.api.admin.users.description
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { TUpdateUserDescriptionRequestData } from "../../../../../../../contracts/src/user/update-user-description.type";

/**
 * @controller AdminUsersController.updateUserDescription
 * @path PUT /api/admin/users/:id/description
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function updateUserDescription(
  connection: IConnection,
  id: string,
  body: updateUserDescription.Input,
): Promise<void> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...updateUserDescription.METADATA,
      path: updateUserDescription.path(id),
    },
    body,
  );
}
export namespace updateUserDescription {
  export type Input = Primitive<TUpdateUserDescriptionRequestData>;

  export const METADATA = {
    method: "PUT",
    path: "/api/admin/users/:id/description",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (id: string) =>
    `/api/admin/users/${encodeURIComponent(id ?? "null")}/description`;
}
