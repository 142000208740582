/**
 * @packageDocumentation
 * @module api.functional.api.files.images
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { FileDestinationDto } from "../../../../../../contracts/src/file-upload/file-destination.dto";
import type { FileMetadataDto } from "../../../../../../contracts/src/file-upload/file-metadata.dto";

/**
 * @controller FilesTemporaryController.createTemporaryImageDestination
 * @path POST /api/files/images
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function createTemporaryImageDestination(
  connection: IConnection,
  fileMetadata: createTemporaryImageDestination.Input,
): Promise<createTemporaryImageDestination.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...createTemporaryImageDestination.METADATA,
      path: createTemporaryImageDestination.path(),
    },
    fileMetadata,
  );
}
export namespace createTemporaryImageDestination {
  export type Input = Primitive<FileMetadataDto>;
  export type Output = Primitive<FileDestinationDto>;

  export const METADATA = {
    method: "POST",
    path: "/api/files/images",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/files/images";
}
