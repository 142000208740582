import { ReactNode, useContext, createContext } from 'react';

// TODO: PLEASE RM AFTER GOOD APPROACH TO HANDLE FE ENVS WILL BE IMPLEMENTED
export const defaultStripeContextValue: IStripeConfig = {
  stripePublishableKey: '',
  stripeSuccessPaymentUrl: '',
  stripeBillingUrl: '',
};

const StripeConfigContext = createContext<IStripeConfig>(defaultStripeContextValue);

export interface IStripeConfig {
  stripePublishableKey: string;
  stripeSuccessPaymentUrl: string;
  stripeBillingUrl: string;
}

export function StripeConfigProvider({
  children,
  config,
}: {
  children: ReactNode;
  config: IStripeConfig;
}) {
  return <StripeConfigContext.Provider value={config}>{children}</StripeConfigContext.Provider>;
}

export const useStripeConfig = () => useContext(StripeConfigContext);
