import { IConnection } from '@nestia/fetcher';

import { getEnvVariables } from '@flounder/next-utils';
import { createHttpClient, HttpClient } from '@flounder/http-client';

export const createBackendSDKConnectionParamsForWeb = (
  httpClient?: HttpClient
): IConnection => {
  if (typeof process !== 'undefined' && process.env.NODE_ENV === 'test') {
    return {
      host: `${getEnvVariables().API_URL}`,
    };
  }

  httpClient = httpClient
    ? httpClient
    : createHttpClient({ url: getEnvVariables().PAGE_URL });

  return {
    host: `${httpClient.options.url}`,
    headers: httpClient.getHeaders(),
  };
};

export const createBackendSDKConnectionParamsForMobile = (
  httpClient: HttpClient
): IConnection => {
  return {
    host: `${httpClient.options.url}`,
    headers: httpClient.getHeaders(),
  };
};
