/**
 * @packageDocumentation
 * @module api.functional.api.email.example
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { MailExampleDto } from "../../../../../../../apps/backend/src/dtos/mail-example.dto";

/**
 * @controller EmailController.sendExampleEmail
 * @path POST /api/email/example
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function sendExampleEmail(
  connection: IConnection,
  dto: sendExampleEmail.Input,
): Promise<void> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...sendExampleEmail.METADATA,
      path: sendExampleEmail.path(),
    },
    dto,
  );
}
export namespace sendExampleEmail {
  export type Input = Primitive<MailExampleDto>;

  export const METADATA = {
    method: "POST",
    path: "/api/email/example",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 201,
  } as const;

  export const path = () => "/api/email/example";
}
