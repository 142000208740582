/**
 * @packageDocumentation
 * @module api.functional.api.payment.products
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { IProductItem } from "../../../../../../contracts/src/payment/types";

/**
 * @controller PaymentController.listProducts
 * @path GET /api/payment/products
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function listProducts(
  connection: IConnection,
): Promise<listProducts.Output> {
  return PlainFetcher.fetch(connection, {
    ...listProducts.METADATA,
    path: listProducts.path(),
  });
}
export namespace listProducts {
  export type Output = Primitive<Array<IProductItem>>;

  export const METADATA = {
    method: "GET",
    path: "/api/payment/products",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/payment/products";
}
