/**
 * @packageDocumentation
 * @module api.functional.api.payment.subscription.cancel
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { CancelSubscriptionDto } from "../../../../../../../contracts/src/payment/cancel-subscription.dto";

/**
 * @controller PaymentController.cancelSubscription
 * @path PUT /api/payment/subscription/cancel
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function cancelSubscription(
  connection: IConnection,
  dto: cancelSubscription.Input,
): Promise<void> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...cancelSubscription.METADATA,
      path: cancelSubscription.path(),
    },
    dto,
  );
}
export namespace cancelSubscription {
  export type Input = Primitive<CancelSubscriptionDto>;

  export const METADATA = {
    method: "PUT",
    path: "/api/payment/subscription/cancel",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/payment/subscription/cancel";
}
