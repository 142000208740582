/**
 * @packageDocumentation
 * @module api.functional.api.ping
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { HealthCheckResult } from "@nestjs/terminus/dist/health-check/health-check-result.interface";

/**
 * @controller HealthController.healthCheck
 * @path GET /api/ping
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function healthCheck(
  connection: IConnection,
): Promise<healthCheck.Output> {
  return PlainFetcher.fetch(connection, {
    ...healthCheck.METADATA,
    path: healthCheck.path(),
  });
}
export namespace healthCheck {
  export type Output = Primitive<HealthCheckResult>;

  export const METADATA = {
    method: "GET",
    path: "/api/ping",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/ping";
}
