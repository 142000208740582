import { ReactNode, useContext, createContext } from 'react';

const MagicBellConfigContext = createContext<IMagicBellConfig | undefined>(undefined);

export interface IMagicBellConfig {
  magicBellClientId: string;
}

export function MagicBellConfigProvider({
  children,
  config,
}: {
  children: ReactNode;
  config: IMagicBellConfig;
}) {
  return (
    <MagicBellConfigContext.Provider value={config}>{children}</MagicBellConfigContext.Provider>
  );
}

export const useMagicBellConfig = () => {
  const ctx = useContext(MagicBellConfigContext);

  if (!ctx) throw new Error('useMagicBellConfig must be used within a MagicBellConfigProvider');

  return ctx;
};
