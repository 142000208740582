/**
 * @packageDocumentation
 * @module api.functional.api.users.access_token
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { GetAccessTokenDto } from "../../../../../../contracts/src/user/get-access-token.dto";
import type { IAccessToken } from "../../../../../../contracts/src/user/get-access-token.schema.type";

/**
 * @controller UsersController.getAccessToken
 * @path POST /api/users/access-token
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getAccessToken(
  connection: IConnection,
  dto: getAccessToken.Input,
): Promise<getAccessToken.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...getAccessToken.METADATA,
      path: getAccessToken.path(),
    },
    dto,
  );
}
export namespace getAccessToken {
  export type Input = Primitive<GetAccessTokenDto>;
  export type Output = Primitive<IAccessToken>;

  export const METADATA = {
    method: "POST",
    path: "/api/users/access-token",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/users/access-token";
}
