/**
 * @packageDocumentation
 * @module api.functional.api.external_login.facebook_mobile
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type {
  FacebookMobileLoginDto,
  FacebookLoginResponseDto,
} from "../../../../../../contracts/src/custom-auth/facebook/facebook-login.dto";

/**
 * @controller ExternalLoginController.facebookMobileLogin
 * @path POST /api/external-login/facebook-mobile
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function facebookMobileLogin(
  connection: IConnection,
  dto: facebookMobileLogin.Input,
): Promise<facebookMobileLogin.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...facebookMobileLogin.METADATA,
      path: facebookMobileLogin.path(),
    },
    dto,
  );
}
export namespace facebookMobileLogin {
  export type Input = Primitive<FacebookMobileLoginDto>;
  export type Output = Primitive<FacebookLoginResponseDto>;

  export const METADATA = {
    method: "POST",
    path: "/api/external-login/facebook-mobile",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/external-login/facebook-mobile";
}
