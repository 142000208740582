/**
 * @packageDocumentation
 * @module api.functional.api.mobile_payment.history
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Resolved, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { TGetMobilePaymentHistory } from "../../../../../../contracts/src/mobile-payment/get-mobile-payment-history.schema.type";
import type { IGetManyQueryParams } from "../../../../../../contracts/src/pagination/paginate-query.schema.type";
import type { Paginated } from "../../../../../../contracts/src/types/paginated";

/**
 * @controller MobilePaymentController.getHistory
 * @path GET /api/mobile-payment/history
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getHistory(
  connection: IConnection,
  requestQueryParams: getHistory.Query,
): Promise<getHistory.Output> {
  return PlainFetcher.fetch(connection, {
    ...getHistory.METADATA,
    path: getHistory.path(requestQueryParams),
  });
}
export namespace getHistory {
  export type Query = Resolved<IGetManyQueryParams>;
  export type Output = Primitive<Paginated<TGetMobilePaymentHistory>>;

  export const METADATA = {
    method: "GET",
    path: "/api/mobile-payment/history",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (requestQueryParams: getHistory.Query) => {
    const variables: URLSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(requestQueryParams as any))
      if (undefined === value) continue;
      else if (Array.isArray(value))
        value.forEach((elem: any) => variables.append(key, String(elem)));
      else variables.set(key, String(value));
    const location: string = "/api/mobile-payment/history";
    return 0 === variables.size
      ? location
      : `${location}?${variables.toString()}`;
  };
}
