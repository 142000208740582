/**
 * @packageDocumentation
 * @module api.functional.api.email_subscriptions
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive, Resolved } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Paginated } from "nestjs-paginate/lib/paginate";

import type { CreateEmailSubscriptionDto } from "../../../../../contracts/src/email-subscriptions/create-email-subscription.dto";
import type { IEmailSubscription } from "../../../../../contracts/src/email-subscriptions/email-subscription.schema.type";
import type { IGetManyQueryParams } from "../../../../../contracts/src/pagination/paginate-query.schema.type";

/**
 * @controller EmailSubscriptionsController.create
 * @path POST /api/email-subscriptions
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function create(
  connection: IConnection,
  payload: create.Input,
): Promise<create.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...create.METADATA,
      path: create.path(),
    },
    payload,
  );
}
export namespace create {
  export type Input = Primitive<CreateEmailSubscriptionDto>;
  export type Output = Primitive<IEmailSubscription>;

  export const METADATA = {
    method: "POST",
    path: "/api/email-subscriptions",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 201,
  } as const;

  export const path = () => "/api/email-subscriptions";
}

/**
 * @controller EmailSubscriptionsController.getAll
 * @path GET /api/email-subscriptions
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getAll(
  connection: IConnection,
  requestQueryParams: getAll.Query,
): Promise<getAll.Output> {
  return PlainFetcher.fetch(connection, {
    ...getAll.METADATA,
    path: getAll.path(requestQueryParams),
  });
}
export namespace getAll {
  export type Query = Resolved<IGetManyQueryParams>;
  export type Output = Primitive<Paginated<IEmailSubscription>>;

  export const METADATA = {
    method: "GET",
    path: "/api/email-subscriptions",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = (requestQueryParams: getAll.Query) => {
    const variables: URLSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(requestQueryParams as any))
      if (undefined === value) continue;
      else if (Array.isArray(value))
        value.forEach((elem: any) => variables.append(key, String(elem)));
      else variables.set(key, String(value));
    const location: string = "/api/email-subscriptions";
    return 0 === variables.size
      ? location
      : `${location}?${variables.toString()}`;
  };
}
