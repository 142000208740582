import { HttpClient, IHttpClientOptions } from './HttpClient';
import { getCookie } from 'cookies-next';

export const createHttpClient = (options: IHttpClientOptions) => {
  return HttpClient.getInstance({
    ...options,
    accessToken: options.accessToken
      ? options.accessToken
      : getCookie('cognitoJwtToken'),
  });
};
