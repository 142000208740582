import { useState, useEffect, forwardRef } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { SingleValue } from 'react-select';
import { TNewPredictions, TPlaceDetails } from '../types';
import { IDropdownOption, SelectDropdown } from '@elpassion/taco/Dropdown';
import { useGoogleAddressParts } from '../hooks';

interface IGooglePlacesAutocompleteSelectProps {
  id: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  apiKey?: string;
  selectCallback?(placeDetails: TPlaceDetails): void;
  errorMessage?: string;
  searchCountry?: string;
}
export const GooglePlacesAutocompleteSelect = forwardRef<
  HTMLSelectElement,
  IGooglePlacesAutocompleteSelectProps
>(
  (
    {
      label,
      placeholder,
      apiKey,
      disabled = false,
      selectCallback,
      errorMessage,
      searchCountry,
      ...rest
    },
    ref
  ) => {
    const [locations, setLocations] = useState<TNewPredictions[]>([]);

    const {
      placePredictions,
      getPlacePredictions,
      isPlacePredictionsLoading,
      placesService,
    } = usePlacesService({
      apiKey: apiKey,
      debounce: 200,
    });

    const createPrediction = (
      placeDetails: TPlaceDetails,
      prediction: TNewPredictions
    ) => {
      if (placeDetails) {
        const address = useGoogleAddressParts(placeDetails);
        if (address) {
          const addressString = `${address?.street} ${
            address?.streetNumber + ' '
          }, ${address.city}, ${address.countryLong}`;
          prediction.label = addressString;
          prediction.value = addressString;
        }
      }
    };

    useEffect(() => {
      if (placePredictions.length) {
        const newPredictions = placePredictions.map((item) => {
          const prediction: TNewPredictions = {
            id: item.place_id,
            label: item.description,
            value: item.description,
          };

          placesService?.getDetails(
            {
              placeId: item.place_id,
            },
            function (placeDetails: TPlaceDetails) {
              createPrediction(placeDetails, prediction);
            }
          );
          return {
            id: item.place_id,
            label: item.description,
            value: item.description,
          } as TNewPredictions;
        });
        setLocations(newPredictions);
      } else {
        setLocations([]);
      }
      // if array dependencies was `placePredictions.length` it doesn't
      // run useEffect after first letter because placePredictions
      // always return 0 or 5 length array
    }, [JSON.stringify(placePredictions)]);

    const getPredictions = (inputValue: string) => {
      getPlacePredictions({
        input: inputValue,
        componentRestrictions: { country: searchCountry as string },
      });
    };

    const onSelectHandler = (selectedValue: TNewPredictions | null) => {
      if (selectCallback && !!selectedValue) {
        placesService?.getDetails(
          {
            placeId: selectedValue.id,
          },
          function (placeDetails: TPlaceDetails) {
            if (placeDetails) selectCallback(placeDetails);
          }
        );
      }

      if (selectCallback && !selectedValue) {
        selectCallback(null);
      }
    };

    return (
      <SelectDropdown

        options={locations}
        isDisabled={disabled}
        placeholderText={placeholder}
        isLoading={isPlacePredictionsLoading}
        {...rest}
        onSelect={(selectedValue) =>
          onSelectHandler(selectedValue as SingleValue<IDropdownOption>)
        }
        instanceId="location"
        onInputChange={getPredictions}
        isClearable
        label={label}
        errorMessage={errorMessage}
        ref={ref}
      />
    );
  }
);
