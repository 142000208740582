/**
 * @packageDocumentation
 * @module api.functional.api.mobile_payment.webhook
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { RevenueCatWebhook } from "../../../../../../contracts/src/mobile-payment/types";

/**
 * @controller MobilePaymentController.handleWebhook
 * @path POST /api/mobile-payment/webhook
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function handleWebhook(
  connection: IConnection,
  body: handleWebhook.Input,
): Promise<void> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...handleWebhook.METADATA,
      path: handleWebhook.path(),
    },
    body,
  );
}
export namespace handleWebhook {
  export type Input = Primitive<RevenueCatWebhook>;

  export const METADATA = {
    method: "POST",
    path: "/api/mobile-payment/webhook",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/mobile-payment/webhook";
}
