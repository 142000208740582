import { ReactNode, useContext, createContext } from 'react';
import Script from 'next/script';
export const defaultGoogleContextValue: IGoogleConfig = {
  googleApiKey: '',
};

const GoogleConfigContext = createContext<IGoogleConfig>(
  defaultGoogleContextValue
);

export interface IGoogleConfig {
  googleApiKey: string;
}

export function GoogleConfigProvider({
  children,
  config,
}: {
  children: ReactNode;
  config: IGoogleConfig;
}) {
  return (
    <GoogleConfigContext.Provider value={config}>
      <Script
        type="text/javascript"
        src={`https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&libraries=places&callback=Function.prototype`}
      />
      {children}
    </GoogleConfigContext.Provider>
  );
}

export const useGoogleConfig = () => useContext(GoogleConfigContext);
