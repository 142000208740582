/**
 * @packageDocumentation
 * @module api.functional.api.admin.feature_flags
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { FeatureFlagDto } from "../../../../../../contracts/src/feature-flags/feature-flag.dto";
import type { Paginated } from "../../../../../../pagination/src/lib/pagination.interfaces";

/**
 * @controller FeatureFlagsController.getFlags
 * @path GET /api/admin/feature-flags
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getFlags(
  connection: IConnection,
): Promise<getFlags.Output> {
  return PlainFetcher.fetch(connection, {
    ...getFlags.METADATA,
    path: getFlags.path(),
  });
}
export namespace getFlags {
  export type Output = Primitive<Paginated<FeatureFlagDto>>;

  export const METADATA = {
    method: "GET",
    path: "/api/admin/feature-flags",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/admin/feature-flags";
}

/**
 * @controller FeatureFlagsController.toggleFlag
 * @path PUT /api/admin/feature-flags
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function toggleFlag(
  connection: IConnection,
  flag: toggleFlag.Input,
): Promise<toggleFlag.Output> {
  return PlainFetcher.fetch(
    {
      ...connection,
      headers: {
        ...connection.headers,
        "Content-Type": "application/json",
      },
    },
    {
      ...toggleFlag.METADATA,
      path: toggleFlag.path(),
    },
    flag,
  );
}
export namespace toggleFlag {
  export type Input = Primitive<FeatureFlagDto>;
  export type Output = Primitive<FeatureFlagDto>;

  export const METADATA = {
    method: "PUT",
    path: "/api/admin/feature-flags",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/admin/feature-flags";
}
