/**
 * @packageDocumentation
 * @module api.functional.api.users.plan.me
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { GetUserPlanDto } from "../../../../../../../contracts/src/user/get-user-plan.dto";

/**
 * @controller UsersPlanController.getPlan
 * @path GET /api/users/plan/me
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getPlan(
  connection: IConnection,
): Promise<getPlan.Output> {
  return PlainFetcher.fetch(connection, {
    ...getPlan.METADATA,
    path: getPlan.path(),
  });
}
export namespace getPlan {
  export type Output = Primitive<GetUserPlanDto>;

  export const METADATA = {
    method: "GET",
    path: "/api/users/plan/me",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/users/plan/me";
}
