/**
 * @packageDocumentation
 * @module api.functional.api.version
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { VersionDto } from "../../../../../contracts/src/version.dto";

/**
 * @controller AppController.getVersion
 * @path GET /api/version
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getVersion(
  connection: IConnection,
): Promise<getVersion.Output> {
  return PlainFetcher.fetch(connection, {
    ...getVersion.METADATA,
    path: getVersion.path(),
  });
}
export namespace getVersion {
  export type Output = Primitive<VersionDto>;

  export const METADATA = {
    method: "GET",
    path: "/api/version",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/version";
}
