import { GoogleAddressParts, TPlaceDetails } from '../types';

export const useGoogleAddressParts = (placeDetails: TPlaceDetails) => {
  if (!placeDetails) return null;
  let streetNumber,
    streetName,
    district,
    city,
    adminAreaShort,
    adminAreaLong,
    countryShort,
    countryLong,
    postalCode;
  if (placeDetails.address_components) {
    for (const addressPart of placeDetails.address_components) {
      const type = addressPart.types[0];
      switch (type) {
        case GoogleAddressParts.STREET_NAME:
          streetName = addressPart.long_name;
          break;
        case GoogleAddressParts.STREET_NUMBER:
          streetNumber = addressPart.long_name;
          break;
        //district
        case GoogleAddressParts.DISTRICT:
          district = addressPart.long_name;
          break;
        //for most cases the city will have type "locality", but e.g. for Brazil it comes as "administrative_area_level_2"
        //if you want to be sure, you have to check it manually for a selected country
        case GoogleAddressParts.ADMIN_AREA_LEVEL_2:
          city = addressPart.long_name;
          break;
        case GoogleAddressParts.LOCALITY:
          city = addressPart.long_name;
          break;
        //state or voivodeship
        case GoogleAddressParts.ADMIN_AREA:
          adminAreaShort = addressPart.short_name;
          adminAreaLong = addressPart.long_name;
          break;
        case GoogleAddressParts.POSTAL_CODE:
          postalCode = addressPart.long_name;
          break;
        case GoogleAddressParts.COUNTRY:
          countryShort = addressPart.short_name;
          countryLong = addressPart.long_name;
          break;
      }
    }
  }

  return {
    street: streetName,
    streetNumber,
    district,
    city,
    adminAreaShort,
    adminAreaLong,
    postalCode,
    countryShort,
    countryLong,
  };
};
