/**
 * @packageDocumentation
 * @module api.functional.api.admin.groups
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

/**
 * @controller AdminGroupsController.getAll
 * @path GET /api/admin/groups
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getAll(connection: IConnection): Promise<getAll.Output> {
  return PlainFetcher.fetch(connection, {
    ...getAll.METADATA,
    path: getAll.path(),
  });
}
export namespace getAll {
  export type Output = Primitive<Array<string>>;

  export const METADATA = {
    method: "GET",
    path: "/api/admin/groups",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/admin/groups";
}
