/**
 * @packageDocumentation
 * @module api.functional.api.users.me
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection, Primitive } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { ExtendedUserDto } from "../../../../../../contracts/src/user/extended-user.dto";

/**
 * @controller UsersController.getUser
 * @path GET /api/users/me
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getUser(
  connection: IConnection,
): Promise<getUser.Output> {
  return PlainFetcher.fetch(connection, {
    ...getUser.METADATA,
    path: getUser.path(),
  });
}
export namespace getUser {
  export type Output = Primitive<ExtendedUserDto>;

  export const METADATA = {
    method: "GET",
    path: "/api/users/me",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: null,
  } as const;

  export const path = () => "/api/users/me";
}
